import { Markdown } from 'components/shared/Markdown/Markdown'
import { ContentTypeView, NextUrl, Sizes } from 'utility/utility'
import { Featured } from 'components/shared/Featured/Featured'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { PageFeaturedFragment } from 'generated/graphql'
import { PageLink } from 'content-types/Page/Page.Link/Page.Link'

import styles from './Page.Featured.module.scss'
import buttonStyles from 'styles/button.module.scss'

export const PageFeatured: ContentTypeView<NextUrl<PageFeaturedFragment>> =
    ({
        summary = '',
        picture,
        siteContext,
        pageContext,
        articleContext,
        ...page
    }) =>
        <Featured
            tags={
                <MetadataTag>Page</MetadataTag>
            }
            link={
                <PageLink
                    siteContext={siteContext}
                    {...page}
                >
                    <ComponentImagePicture
                        {...picture}
                        className={styles.Image}
                        sizes={Sizes.Featured}
                    />
                    { page.title }
                </PageLink>
            }
            summary={
                <>
                    {
                        summary === null ? <></> :
                            <Markdown
                                siteContext={siteContext}
                                pageContext={pageContext}
                                articleContext={articleContext}
                            >
                                {summary}
                            </Markdown>
                    }
                    <PageLink
                        className={buttonStyles.Link}
                        siteContext={siteContext}
                        {...page}
                    >
                        Learn more
                    </PageLink>
                </>

            }
        />
