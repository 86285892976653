'use client'

import { FC, PropsWithChildren } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { SiteContext } from 'context/SiteContext'

export const GoogleReCaptchaProviderClient: FC<PropsWithChildren & {
    siteContext: SiteContext
}> = ({children,siteContext}) =>
    <GoogleReCaptchaProvider reCaptchaKey={siteContext.recaptchaSiteKey} >
        {children}
    </GoogleReCaptchaProvider>
