'use client'

import { useState, useEffect } from 'react'
import { usePathname } from 'next/navigation'
import { ComponentBodyLoginButton } from 'components/body/ComponentBodyLoginButton/ComponentBodyLoginButton'
import { Search } from 'components/search/Search/Search'
import { ContentTypeView } from 'utility/utility'

import { HeaderFragment } from 'generated/graphql'

import styles from './Header.module.scss'

import MenuIcon from 'public/icons/menu.svg'
import MenuIconMagazines from 'public/icons/menu-magazines.svg'

export enum DisplayState {
    Minimal = 'Minimal',
    Navigation = 'Navigation',
    Search = 'Search'
}

export const HeaderClient: ContentTypeView<Omit<HeaderFragment, 'logo'|'primaryLinks'|'secondaryLinks'> & {
    site: string,
    slug: string,
    logoLink: React.ReactNode,
    primaryMenu: React.ReactNode,
    secondaryMenu: React.ReactNode,
}> = ({
    site,
    logoLink,
    primaryMenu,
    secondaryMenu,
    login,
    siteContext,
    pageContext
}) => {

    const pathname = usePathname()

    // Display mode for small screens
    const [ displayState, setDisplayState ] = useState<DisplayState>(DisplayState.Minimal)

    // Reset menu state on navigation
    useEffect( () => setDisplayState(DisplayState.Minimal), [pathname])

    return (
        <>
            <header
                key={pathname}
                className={styles.Header}
                data-display-state={displayState}
                data-site={site}
            >
                <button
                    title='Toggle navigation'
                    onClick={ () => {
                        switch ( displayState ) {
                            case DisplayState.Minimal:
                                setDisplayState(DisplayState.Navigation)
                                break
                            case DisplayState.Navigation:
                                setDisplayState(DisplayState.Minimal)
                                break
                            case DisplayState.Search:
                                setDisplayState(DisplayState.Navigation)
                                break
                        }
                }}>
                    { siteContext.site === 'magazines' ? <MenuIconMagazines/> : <MenuIcon/> }
                </button>
                <menu className={styles.Menu}>
                    {
                        login === null ? <></> :
                            <ComponentBodyLoginButton {...login} siteContext={siteContext} pageContext={pageContext} articleContext={{}} />
                    }
                    { secondaryMenu }
                    <Search
                        siteContext={siteContext}
                        pageContext={pageContext}
                        setDisplayState={setDisplayState}
                    />
                </menu>
                <nav className={styles.Nav}>
                    { logoLink }
                    { primaryMenu }
                </nav>
            </header>
        </>
    )

}
