import { FC } from 'react'

import styles from './Featured.module.scss'

type FeaturedData = {
    tags?: React.ReactNode,
    link: React.ReactNode
    summary?: React.ReactNode
    className?: string
}

export const Featured: FC<FeaturedData> =
    ({
        tags,
        link,
        summary,
        className = ''
    }) =>
        <div className={`${styles.Featured} ${className}`}>
            {
                tags === undefined ? <></> :
                    <header className={styles.Metadata}>
                        {tags}
                    </header>
            }
            {link}
            {summary}
        </div>
