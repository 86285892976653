import { ContentTypeView, NextUrl, Sizes } from 'utility/utility'
import { Featured } from 'components/shared/Featured/Featured'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { DatasetFeaturedFragment } from 'generated/graphql'
import { DatasetLink } from 'content-types/Dataset/Dataset.Link/Dataset.Link'

export const DatasetFeatured: ContentTypeView<NextUrl<DatasetFeaturedFragment>> =
    ({
        picture,
        ...dataset
    }) =>
        <Featured
            tags={
                <MetadataTag>Dataset</MetadataTag>
            }
            link={
                <DatasetLink {...dataset}>
                    {
                        picture === undefined ? <></> :
                            <ComponentImagePicture
                                {...picture}
                                sizes={Sizes.Featured}
                            />
                    }
                    { dataset.title }
                </DatasetLink>
            }
        />
