import { ContentTypeView, NextUrl, Sizes } from 'utility/utility'
import { Featured } from 'components/shared/Featured/Featured'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { InsightReportFeaturedFragment } from 'generated/graphql'
import { InsightReportLink } from 'content-types/InsightReport/InsightReport.Link/InsightReport.Link'

import styles from './InsightReport.Featured.module.scss'

export const InsightReportFeatured: ContentTypeView<NextUrl<InsightReportFeaturedFragment>> =
    ({
        picture,
        ...insightReport
    }) =>
        <Featured
            tags={
                <MetadataTag>Insight Report</MetadataTag>
            }
            link={
                <InsightReportLink {...insightReport}>
                    {
                        picture === undefined ? <></> :
                            <ComponentImagePicture
                                {...picture}
                                sizes={Sizes.Featured}
                            />
                    }
                    { insightReport.title }
                </InsightReportLink>
            }
            className={styles.Featured}
        />
