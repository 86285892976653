import { ContentTypeLink } from 'utility/utility'
import { EventBookingLinkFragment } from 'generated/graphql'

import { EventBookingUrl } from 'content-types/Event/Event.BookingUrl/Event.BookingUrl'

export const EventBookingLink: ContentTypeLink<EventBookingLinkFragment> =
    ({
        children,
        className,
        title,
        seo,
        ...event
    }) =>
        <a
            href={EventBookingUrl(event)}
            className={className}
            title={ seo?.metaTitle ?? title}
        >
            {children}
        </a>
