import { ContentTypeView, NextUrl, formatDate, Sizes } from 'utility/utility'
import { Featured } from 'components/shared/Featured/Featured'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { ArticleFeaturedFragment } from 'generated/graphql'
import { ArticleLink } from 'content-types/Article/Article.Link/Article.Link'

import styles from './Article.Featured.module.scss'

export const ArticleFeatured: ContentTypeView<NextUrl<ArticleFeaturedFragment>> =
    ({
        picture,
        articleType,
        publicationDate,
        ...article
    }) =>
        <Featured
            className={styles.Featured}
            tags={
                <MetadataTag>
                    { articleType?.data?.attributes?.title ?? 'Article' }
                </MetadataTag>
            }
            link={
                <ArticleLink {...article}>
                    {
                        picture === undefined ? <></> :
                            <ComponentImagePicture
                                {...picture}
                                sizes={Sizes.Featured}
                            />
                    }
                    { article.title }
                </ArticleLink>
            }
            summary={
                <span>{formatDate(publicationDate)}</span>
            }
        />
