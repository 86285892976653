'use client'

import { FC } from 'react'
import { useRouter } from 'next/navigation'

import { disableDraftMode } from './actions/disableDraftMode'

import styles from './PreviewIndicator.module.scss'

export const PreviewIndicatorClient: FC = () => {

    const router = useRouter()

    return (
       <div
           className={styles.Indicator}
           onClick={ async () => {
               await disableDraftMode()
               router.refresh()
           }}
       >
           Preview Mode
       </div>
    )

}

