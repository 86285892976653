import { ContentTypeView, NextUrl, Sizes } from 'utility/utility'
import { Featured } from 'components/shared/Featured/Featured'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { MarketReportFeaturedFragment } from 'generated/graphql'
import { MarketReportLink } from 'content-types/MarketReport/MarketReport.Link/MarketReport.Link'

import styles from './MarketReport.Featured.module.scss'

export const MarketReportFeatured: ContentTypeView<NextUrl<MarketReportFeaturedFragment>> =
    ({
        heroArea,
        ...marketReport
    }) =>
        <Featured
            tags={
                <MetadataTag>Market Report</MetadataTag>
            }
            link={
                <MarketReportLink {...marketReport}>
                    {
                        heroArea?.image === undefined || heroArea.image === null ? <></> :
                            <ComponentImagePicture
                                {...(heroArea.image)}
                                title={ marketReport.title }
                                className={styles.Image}
                                sizes={Sizes.Featured}
                            />
                    }
                    { marketReport.title }
                </MarketReportLink>
            }
        />
