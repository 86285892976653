import { ContentTypeView, NextUrl, Sizes } from 'utility/utility'
import { Featured } from 'components/shared/Featured/Featured'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { PortfolioFeaturedFragment } from 'generated/graphql'
import { PortfolioLink } from 'content-types/Portfolio/Portfolio.Link/Portfolio.Link'

export const PortfolioSearchResult: ContentTypeView<NextUrl<PortfolioFeaturedFragment>> =
    ({
        picture,
        ...portfolio
    }) =>
        <Featured
            tags={
                <MetadataTag>Industry</MetadataTag>
            }
            link={
                <PortfolioLink {...portfolio}>
                    {
                        picture === undefined ? <></> :
                            <ComponentImagePicture
                                {...picture}
                                sizes={Sizes.Featured}
                            />
                    }
                    { portfolio.title }
                </PortfolioLink>
            }
        />
