import { ContentTypeView, NextUrl, Sizes } from 'utility/utility'
import { Featured } from 'components/shared/Featured/Featured'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { DatabaseFeaturedFragment } from 'generated/graphql'
import { DatabaseLink } from 'content-types/Database/Database.Link/Database.Link'

export const DatabaseFeatured: ContentTypeView<NextUrl<DatabaseFeaturedFragment>> =
    ({
        picture,
        ...database
    }) =>
        <Featured
            tags={
                <MetadataTag>Database</MetadataTag>
            }
            link={
                <DatabaseLink {...database}>
                    {
                        picture === undefined ? <></> :
                            <ComponentImagePicture
                                {...picture}
                                sizes={Sizes.Featured}
                            />
                    }
                    { database.title }
                </DatabaseLink>
            }
        />
