import { ContentTypeView, NextUrl, Sizes } from 'utility/utility'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { MagazineFeaturedFragment } from 'generated/graphql'
import { MagazineLink } from 'content-types/Magazine/Magazine.Link/Magazine.Link'
import { Featured } from 'components/shared/Featured/Featured'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'

import styles from './Magazine.Featured.module.scss'

export const MagazineFeatured: ContentTypeView<NextUrl<MagazineFeaturedFragment>> =
    ({
        picture,
        ...magazine
    }) =>
        <Featured
            tags={
                <MetadataTag>Magazine</MetadataTag>
            }
            link={
                <MagazineLink {...magazine}>
                    <ComponentImagePicture
                        {...picture}
                        className={styles.Image}
                        sizes={Sizes.Featured}
                    />
                    { magazine.title }
                </MagazineLink>
            }
        />
