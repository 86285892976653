import { ContentTypeView, NextUrl } from 'utility/utility'
import { Featured } from 'components/shared/Featured/Featured'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { ActivityFeaturedFragment } from 'generated/graphql'
import { ActivityLink } from '../Activity.Link/Activity.Link'

export const ActivitySearchResult: ContentTypeView<NextUrl<ActivityFeaturedFragment>> =
    ({
        picture,
        ...activity
    }) =>
        <Featured
            tags={
                <MetadataTag>What We Do</MetadataTag>
            }
            link={
                <ActivityLink {...activity}>
                    {
                        picture === undefined ? <></> :
                            <ComponentImagePicture  {...picture} />
                    }
                    { activity.title }
                </ActivityLink>
            }
        />
