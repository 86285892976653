import { ContentTypeView, NextUrl, Sizes } from 'utility/utility'
import { Featured } from 'components/shared/Featured/Featured'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { EventFeaturedFragment } from 'generated/graphql'
import { EventBookingLink } from 'content-types/Event/Event.BookingLink/Event.BookingLink'
import { formatDateRange, shortVenueSummary } from 'utility/utility'

import styles from './Event.Featured.module.scss'

export const EventFeatured: ContentTypeView<NextUrl<EventFeaturedFragment>> =
    ({
        eventType,
        picture,
        freeDate,
        startDate,
        endDate,
        venue,
        ...event
    }) =>
        <Featured
            tags={
                <MetadataTag>
                    {eventType}
                </MetadataTag>
            }
            link={
                <EventBookingLink {...event}>
                    <ComponentImagePicture
                        {...picture}
                        sizes={Sizes.Featured}
                    />
                    { event.title }
                </EventBookingLink>
            }
            summary={
                <p className={styles.Summary}>
                    <span>
                        {
                            freeDate !== null ? freeDate :
                            startDate === null || endDate === null ? <></> :
                                `${formatDateRange(startDate,endDate)} ${(new Date(startDate).getFullYear())}`
                        }
                    </span>
                    {
                        venue ? <span> | {shortVenueSummary(venue.city,venue.region,venue.country)}</span> : null
                    }
                </p>
            }
        />
