import { ContentTypeView, NextUrl, Sizes } from 'utility/utility'
import { Featured } from 'components/shared/Featured/Featured'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { HomeFeaturedFragment } from 'generated/graphql'
import { HomeLink } from 'content-types/Home/Home.Link/Home.Link'

export const HomeFeatured: ContentTypeView<NextUrl<HomeFeaturedFragment>> =
    ({
        picture,
        ...home
    }) =>
        <Featured
            tags={
                <MetadataTag>Section</MetadataTag>
            }
            link={
                <HomeLink {...home}>
                    <ComponentImagePicture
                        {...picture}
                        sizes={Sizes.Featured}
                    />
                    { home.title }
                </HomeLink>
            }
        />
