import { ContentTypeView, NextUrl, Sizes } from 'utility/utility'
import { Featured } from 'components/shared/Featured/Featured'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { TopicFeaturedFragment } from 'generated/graphql'
import { TopicLink } from 'content-types/Topic/Topic.Link/Topic.Link'

export const TopicFeatured: ContentTypeView<NextUrl<TopicFeaturedFragment>> =
    ({
        picture,
        ...topic
    }) =>
        <Featured
            tags={
                <MetadataTag>
                    Topic
                </MetadataTag>
            }
            link={
                <TopicLink {...topic}>
                    {
                        picture === undefined ? <></> :
                            <ComponentImagePicture
                                {...picture}
                                sizes={Sizes.Featured}
                            />
                    }
                    { topic.title }
                </TopicLink>
            }
        />
