'use client'

import type { FC, Dispatch, SetStateAction } from 'react'
import { useState, useRef } from 'react'
import { useSearchBox } from 'react-instantsearch-hooks-web'
import { DisplayState } from 'content-types/Header/HeaderClient'

import styles from './SearchBox.module.scss'
import MagnifyingGlass from './MagnifyingGlass.svg'
import EndSearch from './EndSearch.svg'

const scrollToTop = (behavior: ScrollBehavior) => (): void =>
window.scrollTo({
    top: 0,
    behavior
})

export const SearchBox: FC<{
    setDisplayState: Dispatch<SetStateAction<DisplayState>>
}> = ({
    setDisplayState
}) => {

    const { query, refine } = useSearchBox()
    const [inputValue, setInputValue] = useState<string>(query)
    const inputRef = useRef<HTMLInputElement>(null)

    const setQuery = (newQuery: string): void => {

        setInputValue(newQuery)
        refine(newQuery)

        if ( newQuery === '' ) {
            setDisplayState(DisplayState.Navigation)
        }
        else {
            setDisplayState(DisplayState.Search)
        }

    }

    return (
        <div className={`ais-SearchBox ${styles.SearchBox}`}>
            <form
                action=''
                className='ais-SearchBox-form'
                noValidate
                onReset={ (event): void => {

                    event.preventDefault()
                    event.stopPropagation()

                    setQuery('')

                    if (inputRef.current) {
                        inputRef.current.focus()
                    }

                }}
            >
                <input
                    ref={inputRef}
                    className='ais-SearchBox-input'
                    maxLength={512}
                    type='search'
                    value={inputValue}
                    onFocus={ (): void => {
                        scrollToTop('smooth')
                    }}
                    onKeyDown={ (): void => {
                        scrollToTop('smooth')
                    }}
                    onChange={ (event): void => {
                        setQuery(event.currentTarget.value)
                    }}
                    onClick={ ({target}): void => {
                        if ( target instanceof HTMLElement && target.closest('button')?.type === 'reset' ) {
                            setDisplayState(DisplayState.Navigation)
                        }
                    }}
                />
                <button
                    className='ais-SearchBox-submit'
                    title='Submit the search query'
                    onClick={ (event): void => {
                        event.preventDefault()
                        scrollToTop('smooth')
                        inputRef.current?.focus()
                    } }
                >
                    <MagnifyingGlass/>
                </button>
                <button
                    className='ais-SearchBox-reset'
                    type='reset'
                    title='Clear the search query'
                    hidden={inputValue === ''}
                >
                    <EndSearch/>
                </button>
            </form>
        </div>
    )

}
